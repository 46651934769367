.criss-cross-image {
  object-fit: cover;
  height: 70vh;
  width: 100%;
}

.criss-cross-image.contain {
  object-fit: contain;
  height: 100%;
  width: 100%;
}

.criss-cross-title {
  font-size: clamp(1rem, 5vw, 1.5rem);
  font-weight: 600;
  margin-bottom: 15px;
}

.criss-cross-description {
  font-size: clamp(0.5rem, 5vw, 1rem);
  font-weight: 200;
  margin-bottom: 2%;
}

.criss-cross-list {
  padding-left: 20px;
  font-size: 1rem;
  margin-bottom: 2%;
  font-size: clamp(0.5rem, 5vw, 1rem);
}

.criss-cross-sublist {
  padding-left: 40px;
  font-size: 1rem;
  margin-bottom: 2%;
  font-size: clamp(0.5rem, 5vw, 1rem);
}

.criss-cross-list li {
  margin-bottom: 5px;
}

/* Fix Slide-In Animations */
.slide-in-left,
.slide-in-right {
  opacity: 0;
}

.slide-in-left {
  animation: slideFromLeft 0.6s forwards ease-in-out;
}

.slide-in-right {
  animation: slideFromRight 0.6s forwards ease-in-out;
}

@keyframes slideFromLeft {
  from {
    opacity: 0;
    transform: translateX(-100px);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes slideFromRight {
  from {
    opacity: 0;
    transform: translateX(100px);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}

@media (max-width: 600px) {
  .criss-cross-image {
    height: 50vh;
  }
}