#banner-image-price {
    height: 100vh; 
    width: 100%;
    background-image: url("../../Image/Section5_1920x1080.jpg");
    background-repeat: no-repeat;
    background-size: 100% 100%;
    display: flex;
    justify-content: center; 
    align-items: center;
    position: relative;
    z-index: 1;
  }
 
  
  @media (max-width: 1023px) {
    #banner-image-price{
      justify-content: center; 
      align-items: center;
      padding-left: 0;
      height: 60vh; 
      background-size: 100% 100%; 
    }
}
  
  @media (max-width: 480px) {
    #banner-image-price {
      padding: 10px;
    }
  }