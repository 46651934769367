#right1,
#right2,
#right3,
#right4 {
  height: 50vh;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  font-size: 20px;
  gap: 15px;
}

#right2,
#right3 {
  background-color: #051522;
  color: white;
  cursor: pointer;
  flex-direction: column;
}

#right1,
#right4 {
  flex-direction: column;
  color: black;
  background-color: white;
  cursor: pointer;
}

@media (max-width: 600px) {
  #right2,
  #right4 {
    background-color: #051522 !important;
    color: white !important ;
  }

  #right1,
  #right3 {
    background-color: white !important;
    color: black !important;
  }
}
