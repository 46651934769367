/* AnimationSection.css */

.animation-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    text-align: center;
  }
  
  .image-section {
    flex: 1;
    max-width: 43%;
  }
  
  .center-content {
    flex: 2;
    padding: 20px;
    max-width: 31%;
    opacity: 0;
    transform: translateY(20px);
    transition: opacity 3s ease-in-out, transform 3s ease-in-out;
  }
  
  .center-content.visible {
    opacity: 1;
    transform: translateY(0);
  }
  
  .center-content h2 {
    font-size: 2em;
    font-weight: bold;
    margin-bottom: 10px;
  }
  
  .center-content h4 {
    font-size: 1.5em;
    font-weight: 600;
    margin-bottom: 15px;
  }
  
  .center-content p {
    font-size: 1em;
    line-height: 1.5;
  }
  
  @media (max-width: 768px) {
    /* Adjust layout for mobile view */
    .animation-container {
      flex-direction: column;
      padding: 20px;
    }
  
    .image-section, .center-content {
      max-width: 100%;
    }
  }
  