* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-family: "KIAbodyLatinbasic_Regular";
}

header {
  height: 58px;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.07),
              0 1px 2px 0 rgba(0, 0, 0, 0.05);
  color: #212529;
}

.nav-area {
  display: flex;
  align-items: center;
  max-width: 100%;
  margin: 0 auto;
  padding: 0 20px;
  height: 58px;
  font-family:"KIAbodyLatinbasic_Regular"
}

.logo {
  text-decoration: none;
  font-size: 25px;
  color: inherit;
  margin-right: 20px;
}

.menus {
  display: flex;
  list-style: none;
  font-family:"KIAbodyLatinbasic_Regular"
}

.menu-items {
  position: relative;
  font-family:"KIAbodyLatinbasic_Regular";
}

.menu-items a {
  display: block;
  font-size: inherit;
  color: inherit;
  text-decoration: none;
  font-family:"KIAbodyLatinbasic_Regular"
}

.menu-items button {
  color: inherit;
  /* font-size: inherit; */
  border: none;
  background-color: transparent;
  cursor: pointer;
  width: '50px';
  font-size: 18px;
  font-family:"KIAbodyLatinbasic_Regular"
}

.menu-items a,
.menu-items button {
  text-align: left;
  padding: 0.7rem 1rem;
  font-family:"KIAbodyLatinbasic_Regular"
}

.menu-items a:hover,
.menu-items button:hover {
  /* background-color: #f2f2f2; */
}

.arrow::after {
  content: "";
  display: inline-block;
  margin-left: 0.28em;
  vertical-align: 0.09em;
  border-top: 0.42em solid;
  border-right: 0.32em solid transparent;
  border-left: 0.32em solid transparent;
}

.dropdown {
  position: absolute;
  right: 0;
  left: 0;
  box-shadow: 0 10px 15px -3px rgba(46, 41, 51, 0.08),
              0 4px 6px -2px rgba(71, 63, 79, 0.16);
  font-size: 0.875rem;
  z-index: 9999;
  min-width: 6rem;
  padding: 0.5rem 0;
  list-style: none;
  background-color: #000;
  border-radius: 0.5rem;
  display: none;
}

.dropdown.show {
  display: block;
}

.dropdown.dropdown-submenu {
  position: absolute;
  left: 100%;
  top: -7px;
}
.enquiry-button, .enquiry-link {
  background-color: white; 
  color: black; 
  text-align: center;
  border: 2px solid black; 
  padding: 10px 20px;
  font-size: 16px;
  font-weight: bold;
  cursor: pointer;
}

@media (min-width: 960px) {
  .enquiry-button, .enquiry-link {
    border-radius: 20px;
  }
}

.enquiry-button:hover, .enquiry-link:hover {
  background-color: white; 
  color: black;
}

.enquiry-item {
  display: inline-block;
  margin: 0 10px;
}


